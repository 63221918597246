<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">My Notes</h1>
                <h2 class="subtitle">
                  Add your notes
                </h2>
              </div>
              <div class="field is-grouped">
                <p class="control">
                  <router-link
                    :to="{ name: 'AccountMyNotesAddNote' }"
                    class="button is-success"
                    >Create Note</router-link
                  >
                </p>
              </div>
            </div>
            <div class="block">
              <div class="columns is-multiline">
                <div
                  v-for="note in orderedNotes"
                  :key="note.id"
                  class="column is-one-fifth is-flex"
                >
                  <div @click="openNote(note)" class="card is-clickable">
                    <div class="card-content">
                      <div class="block">
                        <p class="title is-5">{{ note.title }}</p>
                        <p class="subtitle is-6">{{ note.description }}</p>
                        <p class="title is-6">{{ note.timestamp | date }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { auth, db } from "@/firebase";
import AccountService from "@/services/AccountService";

export default {
  name: "AccountMyNotes",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      notes: [],
    };
  },
  async created() {
    this.$bind(
      "notes",
      db
        .collection("users")
        .doc(this.$store.state.userProfile.uid)
        .collection("notes")
    ).then((notes) => {
      this.notes = notes;
    });
  },
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
  },
  computed: {
    orderedNotes() {
      return this.notes
        .map((x) => ({ ...x, id: x.id }))
        .sort((a, b) => a.timestamp - b.timestamp);
    },
  },
  methods: {
    openNote(note) {
      this.$router.push({
        name: "AccountMyNotesNote",
        params: { note: note.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  flex: 1;
  display: flex;
  transition: all 0.3s ease-in-out;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    transform: scale(1.02, 1.02);
    &::after {
      opacity: 1;
    }
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.has-text-centered.block {
  flex: 1;
}
</style>
